#palvelut{
    padding-top: 5rem;
}

#palvelut h1 {
    text-align: center;
    color: white;
}

#palvelut p {
    text-align: center;
    border-radius: 1rem;
    padding: 0.8rem;
}