.vertical-flowchart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: linear-gradient(to bottom, #7a7a7a, #4d4d4d);;
    margin-left: 20%;
    margin-right: 20%;
    
    border-radius: 2rem;
    color: #fff;
    font-size: large;

    @media (max-width: 800px) {
        margin-left: 5%;
        margin-right: 5%;
    }
    @media (max-width: 500px) {
        font-size: medium;
        margin-left: 0%;
        margin-right: 0%;
    }
    @media (max-width: 400px) {
      margin-left: -5%;
      margin-right: -5%;
    }
  }

  .step {
    text-align: center;
    margin: 20px;
    
  }
  .step-icon{
    border-radius: 50%;
    background-color: #ffffff;
    padding: 0.5rem;
    color: #fff;
  }
  .arrow {
    width: 0;
    height: 5rem;
    border-left: 5px solid #ffffff; /* Adjust color as needed */
    border-radius: 15%;
    transition: opacity 0.3s ease; /* Adding transition for smooth effect */
    opacity: 1; /* Initial opacity */
  }
  
  .arrow.scrolled {
    transition: opacity 0.3s ease;
  }
  