#gallery-header{
    text-align: center;
    padding: 3rem;
}

  #galleria {
    background: linear-gradient(to bottom, #7a7a7a, #4d4d4d 150%);
    color: white;
    justify-content: center;
    margin-top:10rem;
  }
  #gallery-footer{
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 2rem;}

#slider-container {
    border-radius: 2rem;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;

    @media screen and (max-width: 1200px) {
      min-height: 60vh;
    }

    @media screen and (max-width: 800px) {
      min-height: 50vh;
    }

    @media screen and (max-width: 600px) {
      min-height: 30vh;
    }
}
.slider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center align the content */
    max-width: 86%;
    max-height: 90%;
  }
  
  .slider-content {
    display: inline-block;
  }
  
  .image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
  
  #right-arrow {
    position: absolute;
    right: 1%; /* Adjust this value as needed */
    font-size: 4rem;
    color: #7a7a7a;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    height: 100%;
    width: 50%;
    text-align: right;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
  }
  #left-arrow {
    position: absolute;
    left: 1%; /* Adjust this value as needed */
    font-size: 4rem;
    color: #7a7a7a;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    height: 100%;
    width: 50%;
    text-align: right;
    display: flex;
    align-items: center;
    flex-direction: row;
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
    
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  
