#App {
    color: #000000;
    font-family: 'Raleway', sans-serif; /* Assuming 'Raleway' is a font name or imported properly */
    background: linear-gradient(to top, #ffffff 0%, #7a7a7a 500%);
    font-size: large;
}
#content{
    margin-left: 0rem;
    margin-right: 0rem;
}
