nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #7a7a7a, #4d4d4d);
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    z-index: 2;
}

nav .title {
    font-size: 1.5rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

nav ul {
    display: flex;
}

/* Common styles for both normal and mobile views */
nav ul li {
    list-style: none;
    width: auto; /* Adjust as needed */
    text-align: center; /* Adjust as needed */
}

nav ul li  {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
    font-size: x-large;
}

nav ul li:hover {
    background-color: #4d4d4d;
}

nav .menu {
    display: none;
    position: absolute;
    top: 1.5rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: #fff;
    border-radius: 0.2rem;
}

.logo-icon {
    max-width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.logo-icon:hover {
    background-color: #4d4d4d;
    
}


@media (max-width: 520px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
        padding: 0%;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 90%;
        text-align: center;
    }

}