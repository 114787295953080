#home {
    padding-top: 0rem;
  }
  
  .info-box-container {
    position: relative;
  }
  
  .bg-img {
    width: 100%;
    opacity: 0.7;
  }
  
  .home-info-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.9;
    width: 80%;
    text-align: center;
    background: linear-gradient(to left, #7a7a7a, #4d4d4d);
    color: white;
    border-radius: 0.3rem;

  }
  
  .home-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;
    margin-top: 5rem;
  }
  
  .about {
    line-height: 2rem;
    background: linear-gradient(to left, #7a7a7a, #4d4d4d);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    color: white;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .about h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  
  .my-pic {
    max-width: 600px;
    width: 100%;
  }
  
  @media (max-width: 1250px) {
    .home-content {
      padding: 1rem;
    }
    .about h1 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 1100px) {
    #home {
      padding-top: 2rem;
    }
    .home-content {
      flex-direction: column;
    }

  
    .about {
      order: 1;
      max-width: 100%;
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top-right-radius: 1rem;
      border-bottom-left-radius: 0rem;
    }
  
    .my-pic {
      order: 2;
      max-width: 100%;
    }
  }
  
  @media (max-width: 650px) {

    .home-content {
      padding: 1rem;
    }
  }

  @media (max-width:800px) {
    .home-info-box {
        font-size: smaller;
    }
}

@media (max-width:400px) {
    .home-info-box {
        font-size: small;
    }
}

@media (max-width:350px) {
    .home-info-box {
        font-size: x-small;
    }
}


  



