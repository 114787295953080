/* src/components/Footer.css */
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to left, #7a7a7a, #4d4d4d);
    color: white;
    text-align: center;
    font-size: x-large;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  
  .footer-content {
    display: flex;
  }
p {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0.8rem;
}
a{
    color: white;
    text-decoration: none;
}
  
  @media screen and (max-width: 600px) {
    .footer-content {
      flex-direction: column;
    }
    p{
        margin: 0.3rem;
        font-size: medium;
    }
}
  