.info-box{
    text-align: center;
    background: linear-gradient(to left, #7a7a7a, #4d4d4d);
    color: white;

    padding: 1rem;
    margin-bottom: 10rem;
    margin-top: 5rem;

    @media screen and (max-width: 800px) {
        font-size: medium;
        
    }
    @media screen and (max-width: 400px) {
        font-size: smaller;
        
    }
    @media screen and (max-width: 310px) {
        font-size: small;
        
    }
}

